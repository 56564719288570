<template>
  <div class="flex flex-col items-center justify-center h-screen w-screen">
    <h1>{{ error.statusCode }} - {{ error.message }}</h1>
    <nuxt />
  </div>
</template>

<script>
export default {
  props: ['error']
}
</script>
